
@primary-color: #0065bd; // primary color for all components
@link-color: #0065bd; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: rgba(0, 0, 0, 0.85); // heading text color
@text-color: rgba(0, 0, 0, 0.65); // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 2px; // major border radius
@border-color-base: #d9d9d9; // major border color
@box-shadow-base: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08),
  0 9px 28px 8px rgba(0, 0, 0, 0.05); // major shadow for layers

@colorBgLayout: red;
@layout-header-background: white;
@layout-header-color: #283046;
@layout-header-height: 81px;

@typography-title-font-weight: 400;
@font-family: 'Avenir-Book', "Avenir-Light", "Montserrat", "Arial";
// 'AvenirWorld-Regular', 'Avenir-Light', 'MXiangHeHeiSCv4forDSS-Bk', 'MXiangHeHeiTCv4forDSS-Bk', 'SeolSansv4forDSS-Bk', 'TazuganeInfov4forDSS-Bk', 'Arial', 'sans-serif';
@typography-title-color: red;


.ant-layout, .ant-layout-footer {
  background-color: #F0F5FA!important;
}

:root{
  --text-primary:#000;
  --bgPrimary:#fff;
  --bgSecondary: #F9F9F9;
  --pink: red;
  --light-blue: #e6f7ff;
  --border: #d9d9d9;
}

.ant-menu-horizontal {
  border: none;
}

// #161d31
// #283046



h1, h2, h3, h4, h5, h6  {
  color: #000;
}


.ant-btn, .ant-switch , 
.ant-menu-title-content,
.ant-menu-item, 
.ant-radio-button-wrapper, .ant-select-selector,
.ant-select-selection-item, 
.ant-pagination-item,
.ant-layout-header {
  height: auto!important;
}

.ant-carousel .slick-dots li button {

  background: #0065bd!important;
}

.ant-image-img {
  width: auto!important;
}
.ant-carousel-vertical .slick-dots li button, .ant-carousel-vertical .slick-dots li.slick-active button{
  width: 3px;
}




html {
  overscroll-behavior: contain;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  
}

.mt-1 {
  margin-top: 10px;
}
.mt-2 {
  margin-top: 20px;
}
.mt-3 {
  margin-top: 30px;
}
.mt-4 {
  margin-top: 40px;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-1 {
  margin-bottom: 10px;
}
.mb-2 {
  margin-bottom: 20px;
}
.mb-3 {
  margin-bottom: 30px;
}
.mb-4 {
  margin-bottom: 40px;
}

.ml-1 {
  margin-left: 10px;
}

.app{
  display: flex;
  overflow: hidden;
}

.ant-layout-content {
  padding: 0 50px
}

.breadcrumbs {
  margin: 16px 0; 
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.ant-menu-horizontal, .ant-layout .ant-layout-sider, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark, .ant-menu.ant-menu-dark .ant-menu-sub {
  background: #114584;
}



.ant-spin-dot-item {
  background-color: #0065bd;
}


.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected, 
.ant-menu.ant-menu-dark .ant-menu-item-selected, 
.ant-menu.ant-menu-dark .ant-menu-item-selected:active {
  background: #D99208;
}

.ant-switch-checked {
  background: #D99208;

}



.main{
  flex: 0.8;
  width: 100%;
  
}

.document-card {
  margin: 40px 0 40px 0;
}


.footer {
  padding: 20px;
  align-items: center;
}

.footer a {
  color: #0065bd;
}


.site-layout-content {
  min-height: 280px;
  padding: 24px;
  background: #fff;
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}



.mt-2 {
  margin-top: 1.5rem!important;
}
.mb-75 {
  margin-bottom: .75rem!important;
}

ul {
  margin-left: 10px;
  list-style-type: none;
  
}

.eigenschaften li {
  margin-bottom: 3px;
}

.eigenschaften li:before {
  content: "";
  position:relative;
  left: -10px;
  background: #0065bd;

  display:inline-block;
  width:10px;
  height:10px;
}

.layout-div {
  margin-top: 50px;
}

.disclaimer {
  width: 400px;
}
@media (max-width: 554px) {
  .ant-layout-content {
    padding: 0 10px
  }
  .layout-div {
    margin-top: 20px;
  }
  .disclaimer {
    width: 300px;
  }
}