@import (less) "../../main.less";



.input_group {
    display: flex;
    flex-direction: column;
    justify-items: center;
    width: 100%;
}

.input_number {
    * {
        width: 100%;
        margin: 0.3rem;
    }
    display: flex;
    flex-direction: row;
}


.submit-btn {
    margin-top: 0.2rem;
    margin-left: 0.3rem;
    margin-right: 0.3rem;
}


// table design
#eea_table {
    
    * {
        text-align: center;
    }

}

#ggt {
    display: flex;
    justify-content: center;
    margin: 1rem;
}


