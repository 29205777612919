

#truth-table {
    
    * {
        text-align: center;
    }


    
}
